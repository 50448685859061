
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import FleetCalendar from '@/modules/cars/modules/fleet/components/calendar/fleet-calendar.vue';
import type UserService from '@/modules/user/user.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';

@Component({
    components: {
        ProviderCard,
        FleetCalendar,
        LoaderWrapper,
    },
})
export default class FleetDensity extends Vue {
    @inject(KEY.UserService) userService!: UserService;
    @inject(FleetServiceS) private fleetService!: FleetService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
    }

    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }

    get sources() {
        const { dataSources } = this.fleetService;
        if (!dataSources) {
            return [];
        }
        return dataSources;
    }
}
